import React, { useState, useEffect } from "react";
import { Table, Card, Space } from "lib";
import { Form, message, Modal, TablePaginationConfig } from "antd";
import { Layout } from "layout";
import {
  ClientGroupsClient,
  ClientGroupCustomizableEmailTypes,
  SupportedLanguageClient,
  UpdateClientGroupEmailGreetingCommand,
} from "@api";
import { showError } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import ClientGroupDetailMenu from "@components/Admin/ClientGroup/ClientGroupDetailMenu";
import Header from "@components/Admin/ClientGroup/Header";
import ContentKeyValueTable from "@components/Admin/Language/ContentKeyValueTable";
import { getIsAdmin, getIsClientAdmin } from "@services/auth";

const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};
interface Params {
  id: number;
}
interface Props {
  params: Params;
}
interface IState {
  type: ClientGroupCustomizableEmailTypes;
  name: string;
  content?: { [key: string]: string } | undefined;
}

const GroupLevelEmailCustomizationPage = ({ params }: Props) => {
  const auth = new SubpageAuth(
    SECTION.Admin,
    "ClientGroups",
    "EmailCustomization"
  );
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const groupId = params.id;
  const { formatMessage } = useIntl();

  let defaultState: IState[] = [
    {
      type: ClientGroupCustomizableEmailTypes.Registration,
      name: formatMessage({ id: "ClientGroupEmailCustomRecordRegistrationEmailToken" })
    },
    { type: ClientGroupCustomizableEmailTypes.Report, 
      name: formatMessage({ id: "ClientGroupEmailCustomRecordReportEmailToken" })
    },
    {
      type: ClientGroupCustomizableEmailTypes.Reminder,
      name: formatMessage({ id: "ClientGroupEmailCustomRecordReminderEmailToken" })
    },
  ];

  if(!getIsClientAdmin()){
  defaultState = [
    {
      type: ClientGroupCustomizableEmailTypes.Registration,
      name: formatMessage({ id: "ClientGroupEmailCustomRecordRegistrationEmailToken" })
    },
    { type: ClientGroupCustomizableEmailTypes.Report, 
      name: formatMessage({ id: "ClientGroupEmailCustomRecordReportEmailToken" })
    },
    {
      type: ClientGroupCustomizableEmailTypes.Reminder,
      name: formatMessage({ id: "ClientGroupEmailCustomRecordReminderEmailToken" })
    },
    {
      type: ClientGroupCustomizableEmailTypes.RegistrationCustomTitle,
      name: formatMessage({ id: "ClientGroupEmailCustomRecordRegistrationEmailTitle" })
    },
    { type: ClientGroupCustomizableEmailTypes.ReportCustomTitle, 
      name: formatMessage({ id: "ClientGroupEmailCustomRecordReportEmailTitle" })
    },
    {
      type: ClientGroupCustomizableEmailTypes.ReminderCustomTitle,
      name: formatMessage({ id: "ClientGroupEmailCustomRecordReminderEmailTitle" })
    },
    {
      type: ClientGroupCustomizableEmailTypes.RegistrationCustomEmail,
      name: formatMessage({ id: "ClientGroupEmailCustomRecordRegistrationEmailTemplate" })
    },
    { type: ClientGroupCustomizableEmailTypes.ReportCustomEmail, 
      name: formatMessage({ id: "ClientGroupEmailCustomRecordReportEmailTemplate" })
    },
    {
      type: ClientGroupCustomizableEmailTypes.ReminderCustomEmail,
      name: formatMessage({ id: "ClientGroupEmailCustomRecordReminderEmailTemplate" })
    },
  ];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();

  const allLanguageFetch = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
      const _init = {
        ...init,
        headers: {
          ...init?.headers,
          "accept-language": "*",
        },
      };
      return fetch(url, _init);
    },
  };

  const clientGroupsClient = new ClientGroupsClient(
    undefined,
    allLanguageFetch
  );
  const supportedLanguageClient = new SupportedLanguageClient();

  const [state, setState] = useState({
    items: defaultState,
    pagination: defaultPagination,
    loading: false,
  });

  async function fetchItems(params: any = { pagination: state.pagination }) {
    if (auth.cannot(ACTION.List, "EmailCustomization")) return;

    setState({
      ...state,
      loading: true,
    });

    const supportedLocales =
      await supportedLanguageClient.getSupportedLanguages(undefined);
    const res = await clientGroupsClient.getClientGroupEmailGreeting(groupId);

    const baseContent: { [key: string]: string } = {};

    supportedLocales
      .map((v) => v.id as string)
      .forEach((element) => {
        baseContent[element] = "";
      });
    const temp = state.items.slice();
    temp[0].content = { ...baseContent, ...res.registrationEmail };
    temp[1].content = { ...baseContent, ...res.reportEmail };
    temp[2].content = { ...baseContent, ...res.reminderEmail };
    if(!getIsClientAdmin()){
      temp[3].content = { ...baseContent, ...res.registrationEmailCustomTitle };
      temp[4].content = { ...baseContent, ...res.reportEmailCustomTitle };
      temp[5].content = { ...baseContent, ...res.reminderEmailCustomTitle };
      temp[6].content = { ...baseContent, ...res.registrationCustomEmail };
      temp[7].content = { ...baseContent, ...res.reportCustomEmail };
      temp[8].content = { ...baseContent, ...res.reminderCustomEmail };
    }
    setState({
      ...state,
      items: temp,
      pagination: {
        ...params.pagination,
        // total: 4,
      },
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleEditContent(parent: any, target: any, data: any) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );

    try {
      if (!target || !parent) {
        throw "Error";
      }

      await clientGroupsClient.updateEmailGreeting(
        new UpdateClientGroupEmailGreetingCommand({
          clientGroupId: groupId,
          clientGroupCustomizableEmailTypes: parent.type,
          content: data.value,
          languageId: target.key,
        })
      );

      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  const columns = [
    {
      // title: "Name",
      title: <FormattedMessage id="ClientGroupEmailCustomTableHeaderName" />,
      dataIndex: "name",
      key: "name",
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="ClientGroupEmailCustomizationNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <Header id={groupId}></Header>
          <ClientGroupDetailMenu id={groupId} />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form>{/* button on left hand side block */}</Form>
            </Space>
            <Space wrap></Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={"name"}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender: (record: IState) => (
                <ContentKeyValueTable
                  content={record.content}
                  parent={record}
                  handleAction={handleEditContent}
                  editable={auth.can(ACTION.Edit, "EmailCustomization")}
                />
              ),
              rowExpandable: () => true,
            }}
          />
        </Space>
      </Card>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default GroupLevelEmailCustomizationPage;
